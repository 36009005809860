<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

const emit = defineEmits(['hide'])

function hide() {
  emit('hide')
  modal.hide()
}
</script>

<template>
  <base-mdl
    width="sme"
    layout="v2"
    border-radius="md"
    modal-class="border"
    padding="xs"
  >
    <template #header>
      <header
        class="relative z-40 flex flex-col border-b transition duration-200"
      >
        <div class="flex flex-col items-center justify-between">
          <h2 class="py-5 text-2xl tracking-tight text-eonx-neutral-800">
            Problem generating PayID
          </h2>
          <div
            class="absolute right-6 top-1/2 flex -translate-y-1/2 transform items-center"
          >
            <base-button
              ref="closeButton"
              icon="heroicons/solid/x-mark"
              :size="32"
              alt="close"
              data-test="modal-close"
              @click="hide"
            />
          </div>
        </div>
      </header>
    </template>

    <div class="space-y-5 px-1 pb-1 pt-6 text-eonx-neutral-800">
      <div>
        There was an unexpected error when generating your PayID details, please
        wait a moment and try again.
      </div>
      <div>If the problem persists please contact support.</div>
    </div>

    <template #footer>
      <div class="border-default flex gap-x-3 border-t p-6">
        <base-button class="flex-1" @click="hide">Back</base-button>
      </div>
    </template>
  </base-mdl>
</template>
